<template>
  <div>
    <div class="hero">
      <div id="credito" class="credito-info">
        <div class="row">
          <div class="col-md-6 subtitle">
            <h1>
              Adquiere tu casa ahora. <br />
              Bien para Bien <br />
              en alianza con Skypark
            </h1>
          </div>
          <div class="col-md-6">
            <div id="registro" class="container">
            <Form />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Desarrollos />

    <div id="skypark" class="container mt-5 mb-5">
      <div class="row text-center">
        <div class="col-md-6">
          <h3>Skypark</h3>
          <p>
            Diseñados con una arquitectura de vanguardia, disfrutarás de una
            vida en equilibrio con la ecología, recreación y tecnología para
            llevar un estilo de vida saludable y sostenible. Dos desarrollos
            departamentales: Skypark San Lorenzo y Skypark Amores, exclusivos,
            con acabados de lujo y ubicaciones en las mejores zonas de la Ciudad
            de México.
          </p>
          <div class="d-flex justify-content-center">
            <img
              src="@/assets/img/skypark-bpb.png"
              class="col-sm-8 col-xs-12"
            />
          </div>
        </div>

        <div class="col-md-6">
          <h3>Promotores Bien para Bien</h3>
          <p>
            Bien para Bien, otorgando créditos a millones de familias mexicanas,
            contamos con un programa para que cualquiera que esté interesado en
            formar parte pueda formar parte de nuestros más de 17 mil socios. Si
            quieres obtener más información visita
            <a href="https://promotores.bienparabien.com" target="_blank"
              >https://promotores.bienparabien.com</a
            >
          </p>
          <div class="d-flex justify-content-center">
            <img
              src="https://static.bienparabien.com/apps/img/logoBpB.png"
              class="col-sm-4 col-xs-12"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="ventajas" class="descript container">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="title-bpb center-text">CONTÁCTANOS</h3>
          <br />
          <p style="text-align:center">
            En Bien para Bien, contamos con un equipo de Socios Promotores que
            están dispuestos a solucionarte cualquier duda.
          </p>
          <br />
          <br />
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="panel">
            <span class="icon">
              1
            </span>
            <p>Regista tu solicitud <br />en este formulario.</p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="panel">
            <span class="icon">2</span>
            <p>
              Serás contactado por uno de<br />
              nuestros socios promotores BpB.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="panel">
            <span class="icon">3</span>
            <p>
              Obtén el acompañamiento para<br />
              que puedas obtener tu casa.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" class="full-container">
			<div class="container">
				<div class="col-sm-12">
					<h4 class="text-center m-5"><b><a href="#credito">¡Obtén tu casa ahora!</a></b></h4>
				</div>
			</div>
		</div>

  </div>
</template>

<script>
import Desarrollos from '@/components/Desarrollos.vue';
import Form from '@/components/Form.vue';

export default {
  components :{
    Desarrollos,
    Form
  }
}
</script>
